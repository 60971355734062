<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from 'vue-router';
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { filterIcon } from "@progress/kendo-svg-icons";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { createGridHelper } from "~/store/useGridHelper";
import { deleteTask, useListTasks } from "~/api/activity/activity";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import { notify } from "notiwind";
import { updateHandler } from "~/utils/error-handler";
import { type Task, type User } from "~/model";
import {
  useListTaskActivities,
  deleteTaskActivity
} from "~/api/task-activity/task-activity";
import { customAxios } from "~/api/custom-axios";

const router = useRouter();
const route = useRoute();
const organizationId = computed(() => route.params.organizationId as string);

const gridHelper = createGridHelper("project-tasks", {
  page: 1,
  pageSize: 10,
  sort: [{ field: "createdOn", dir: "desc" }],
  showFilter: false,
  filter: null,
  query: ""
});

const { pageSettings, sort, showFilter, filter, gridQuery } = storeToRefs(gridHelper);

const showNewTask = ref(false);
const selectedTask = ref<Task | null>(null);

const taskQuery = computed(() => ({
  ...gridQuery.value,
  sort: "createdOn:desc",
  filter: {
    logic: "and",
    filters: [{
      field: "project.organization.id",
      operator: "eq",
      value: organizationId.value
    }]
  }
}));

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListTasks(taskQuery);

const filteredTasks = computed(() => {
  const allTasks = data.value?.content || [];
  return allTasks.filter(task =>
    task.project?.organization?.id === organizationId.value
  );
});

const filteredTotal = computed(() => filteredTasks.value.length);
const gridKey = computed(() => `${organizationId.value}-${filteredTotal.value}`);

watch(filteredTotal, (newTotal) => {
  gridHelper.setTotal(newTotal);
  gridHelper.saveState();
});

const computedQuery = computed({
  get: () => gridHelper.query,
  set: (value) => gridHelper.setQuery(value)
});

const columns = [
  {
    title: "Task Name",
    field: "title",
    cell: "taskName"
  },
  {
    title: "Description",
    field: "description",
    cell: "description",
  },
  {
    title: "Project Name",
    field: "project.name",
  },
  {
    title: "Assignee",
    field: "assignee",
    cell: "assignee",
  },
  {
    title: "Actions",
    field: "description",
    cell: "actions",
    width: "120px",
    filterable: false,
    sortable: false
  },
];

const navigateToTask = (task: Task) => {
  const orgId = route.params.organizationId;
  const projectId = task.project?.id;

  if (orgId && projectId && task.id) {
    router.push({
      path: `/f/o/${orgId}/p/${projectId}/t/${task.id}`,
      state: { taskData: task }
    });
  }
};

const editTask = (task: Task) => {
  if (!task.id) {
    console.error('Cannot edit task without ID');
    return;
  }
  selectedTask.value = task;
  showNewTask.value = true;
};

const formatUserDisplay = (user: User | null): string => {
  if (!user) return '-';
  return `${user.firstName} ${user.lastName} (${user.email})`;
};

const handleTaskUpdated = async () => {
  await refetch();
  selectedTask.value = null;
  updateHandler(Promise.resolve(), "Task updated successfully");
};

const handleDialogClose = () => {
  showNewTask.value = false;
  selectedTask.value = null;
};

const doDeleteTask = async (task: Task) => {
  if (!task?.id) {
    console.error('Cannot delete task: Missing task ID');
    return;
  }

  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: `Delete task ${task.title}?`,
    message: "This action cannot be undone. All associated activities will also be deleted.",
  });

  if (!isCanceled) {
    try {
      // Instead of using useListTaskActivities, use a direct API call
      const response = await customAxios({
        url: `/api/taskActivities`,
        method: 'GET',
        params: {
          page: 1,
          pageSize: 100,
          filter: `task.id:'${task.id}'`,
          sort: ""
        }
      });

      const activities = response.content;

      if (activities?.length) {
        // Delete all activities first
        await Promise.all(
          activities.map(activity =>
            deleteTaskActivity(activity.id)
          )
        );
      }

      // Then delete the task
      await deleteTask(task.id);

      notify({
        group: "generic",
        title: "Success",
        text: "Task and associated activities deleted successfully",
      }, 3000);
      await refetch();
    } catch (error) {
      console.error("Error deleting task:", error);
      updateHandler(
        Promise.reject(error),
        "Failed to delete task. Please try again."
      );
    }
  }
};

const truncateDescription = (text: string, maxLines: number = 3): string => {
  if (!text) return '-';
  const words = text.split(' ');
  const truncated = words.slice(0, 30).join(' ');
  return words.length > 30 ? `${truncated}...` : truncated;
};

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});
</script>

<template>
  <div>
    <div class="mx-4">
      <Toolbar class="mb-1 border-0 bg-white">
        <label for="query" class="sr-only">Query</label>
        <KodexaTextInput
          id="query"
          v-model="computedQuery"
          :show-clear="true"
          type="text"
          name="query"
          class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm"
          placeholder="Search Tasks"
        />
        <Button
          class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          :svg-icon="filterIcon"
          title="Filter"
          :togglable="true"
          @click="gridHelper.toggleFilter()"
        />
        <ToolbarSpacer />
        <KodexaButton
          id="newTask"
          icon="plus"
          title="New Task"
          @click="showNewTask = true"
        >
          New Task
        </KodexaButton>
      </Toolbar>
      <Grid
        :key="gridKey"
        :data-items="filteredTasks"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="filteredTotal"
        :page-size="pageSettings.take as number"
        :take="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error"
            :is-loading="isLoading"
            :is-error="isError"
            no-records-message="No tasks found"
          />
        </GridNoRecords>
        <template #taskName="{ props }">
          <td>
            <div class="flex items-center">
              <div
                class="flex-0 cursor-pointer font-bold text-gray-900 hover:text-blue-600"
                @click="navigateToTask(props.dataItem)"
              >
                {{ props.dataItem?.title }}
              </div>
            </div>
          </td>
        </template>
        <template #description="{ props }">
        <td>
          <div class="line-clamp-3 max-w-md text-sm text-gray-600">
            {{ truncateDescription(props.dataItem.description) }}
          </div>
        </td>
      </template>
        <template #assignee="{ props }">
          <td>
            <div class="flex items-center">
              <KodexaUserAvatar
                v-if="props.dataItem.assignee"
                :user="props.dataItem.assignee"
                size="8"
                class="mr-2"
              />
              <span class="text-sm text-gray-900">
                {{ formatUserDisplay(props.dataItem.assignee) }}
              </span>
            </div>
          </td>
        </template>
        <template #actions="{ props }">
          <td>
            <div class="flex justify-center space-x-2">
              <KodexaButton
                icon="pencil"
                title="Edit Task"
                size="small"
                @click="editTask(props.dataItem)"
              >
              </KodexaButton>
              <KodexaButton
                icon="delete"
                title="Delete Task"
                size="small"
                type="danger"
                @click="doDeleteTask(props.dataItem)"
              >
              </KodexaButton>
            </div>
          </td>
        </template>
      </Grid>
      <NewTaskPopover
        v-model="showNewTask"
        :task-to-edit="selectedTask"
        :organization-id="organizationId"
        @update:task-to-edit="(val) => selectedTask = val"
        @task-created="refetch"
        @task-updated="handleTaskUpdated"
        @update:modelValue="handleDialogClose"
      />
    </div>
  </div>
</template>

<style scoped>
:deep(button .icon) {
  margin-right: 0 !important;
}
</style>
