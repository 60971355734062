import NProgress from "nprogress";
import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import appStore from "~/store";
import AppContainer from "~/views/app/app-container.vue";
import AppHome from "~/views/app/home/app-home.vue";
import FlowContainer from "~/views/flow/flow-container.vue";
import FlowHome from "~/views/flow/home/flow-home.vue";
import AppAdmin from "~/views/app/home/app-admin.vue";
import ProjectAdmin from "~/views/app/organizations/project/project-admin.vue";
import OrganizationAdmin from "~/views/app/organizations/home/organization-admin.vue";
import OrganizationNew from "~/views/app/organizations/home/choose-organization-subscription.vue";
import AuthCallback from "~/views/app/user-signup.vue";
import TermsCondition from "~/views/app/terms-condition.vue";
import ProfilePopup from "~/views/app/profile-popup.vue";
import TaskView from "~/views/flow/organizations/project/task/task-view.vue";
import ProjectTasks from "~/components/dashboard/widgets/project/project-tasks.vue";

const Organization = () => import("~/views/app/organizations/organization.vue");
const Workspace = () => import("~/views/app/organizations/project/workspace/workspace.vue");
const Profile = () => import("~/views/app/profile/profile.vue");
const Kiosk = () => import("~/views/kiosk/kiosk.vue");
const WorkspaceSidecar = () => import("~/views/sidecar/workspace-sidecar.vue");
const OrganizationHome = () => import("~/views/app/organizations/home/organization-home.vue");
const ProjectHome = () => import("~/views/app/organizations/project/project-home.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/a/home",
  },
  {
    path: "/capture/:taskId",
    name: "Loupe",
    component: () => import("~/views/capture/capture-task.vue"),
  },
  {
    path: "/404",
    name: "ErrorPage",
    component: () => import("~/views/errorPages/index.vue"),
  },
  {
    path: "/you-ve-got-mail",
    name: "Signup",
    component: AuthCallback,
  },
  {
    name: "sidecar",
    path: "/sc/:sidecarId",
    meta: { requiresAuth: true },
    component: WorkspaceSidecar,
  },
  {
    name: "kiosk",
    path: "/kiosk",
    meta: { requiresAuth: true },
    component: Kiosk,
    children: [
      {
        path: "o/:organizationId/p/:projectId/:workspaceId/:dataFormRef/:searchText/:storeId?",
        component: Kiosk,
      },
    ],
  },
  {
    name: "payment",
    path: "/new/payments",
    component: OrganizationNew,
    meta: { requiresAuth: true },
  },
  {
    path: "/a",
    name: "ClearStudio",
    meta: { requiresAuth: true },
    component: AppContainer,
    redirect: "/a/home",
    beforeEnter: authGuard,
    children: [
      {
        path: "profile",
        component: Profile,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "home",
        component: AppHome,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "callback",
        component: AppHome,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "admin",
        component: AppAdmin,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "terms",
        component: TermsCondition,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "user-profile",
        component: ProfilePopup,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "o",
        children: [
          {
            path: "new",
            component: OrganizationNew,
          },
          {
            path: ":organizationId",
            component: Organization,
            children: [
              {
                path: "p",
                children: [
                  {
                    path: ":projectId",
                    children: [
                      {
                        path: "home",
                        name: "projectHome",
                        component: ProjectHome,
                      },
                      {
                        path: "workspaces/:workspaceId",
                        component: Workspace,
                      },
                      {
                        path: "kiosks/:workspaceId/:dataFormRef/:searchText",
                        component: Kiosk,
                      },
                      {
                        path: "admin",
                        component: ProjectAdmin,
                      },
                    ],
                  },
                ],
              },
              {
                path: "home",
                component: OrganizationHome,
              },
              {
                path: "admin",
                component: OrganizationAdmin,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/f",
    name: "ClearFlow",
    meta: { requiresAuth: true },
    component: FlowContainer,
    redirect: "/f/home",
    beforeEnter: authGuard,
    children: [
      {
        path: "profile",
        component: Profile,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "home",
        component: FlowHome,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "terms",
        component: TermsCondition,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "user-profile",
        component: ProfilePopup,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "o/:organizationId",
        children: [
          {
            path: "home",
            component: ProjectTasks, 
            meta: {
              header: {
                title: "Organization",
              },
            },
          },
          {
            path: "p/:projectId/t/:taskId",
            component: TaskView,
            meta: {
              header: {
                title: "Tasks",
              },
            },
          }
        ],
      }
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(() => {
  NProgress.done();
  useIntercom().update();
  useIntercom().hide();
});

router.beforeEach(async (to, from, next) => {
  const currentUrl = to.fullPath;
  localStorage.setItem("lastVisitedUrl", currentUrl);
  if (to.path === "/you-ve-got-mail") {
    next();
    return;
  }

  if (!await authGuard(to)) {
    next(false);
    appStore.userStore.login(to.query.signup === "true");
    return;
  }

  if (!NProgress.isStarted()) {
    NProgress.start();
  }

  if (to.params.workspaceId) {
    appStore.workspaceStore.loadWorkspace(to.params.workspaceId);
  } else {
    if (!await appStore.workspaceStore.clearCurrentWorkspace()) {
      next(false);
      return;
    }
  }

  if (to.params.projectId) {
    appStore.projectStore.loadProject(to.params.projectId);
  } else {
    if (!await appStore.projectStore.clearCurrentProject()) {
      // We couldn't clear the project so we need to stop
      // the navigation
      next(false);
      return;
    }
  }

  if (to.params.organizationId) {
    await appStore.organizationStore.loadOrganization(to.params.organizationId);
  } else {
    if (!await appStore.organizationStore.clearCurrentOrganization()) {
      next(false);
      return;
    }
  }

  if (to.meta.header) {
    appStore.platformStore.setCurrentHeading(to.meta.header);
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!await appStore.userStore.isLoggedIn()) {
      next({ path: "/a/home" });
    } else {
      // check if user has viewed profile
      // route should not include "/a/terms", "/a/profile", or "/new/payments" to prevent infinite loop
      if (to.path === "/a/home" && appStore.userStore.hasNoUserDetails()) {
        next({ path: "/a/user-profile" });
        return;
      }

      // check if user has accepted terms / part of any membership
      // route should not be in either of those path to prevent infinite loop
      if (to.path === "/a/home" && !appStore.userStore.needsToAcceptTerms()) {
        next({ path: "/a/terms" });
        return;
      }

      // check if user is part of any memberships
      // route should not include "/new/payments" to prevent infinite loop
      if (to.path !== "/new/payments" && to.path !== "/a/terms" && to.path !== "/a/user-profile" && appStore.userStore.hasNoMemberships()) {
        next({ path: "/new/payments" });
        return;
      }

      const singleMembershipPath = appStore.userStore.singleMembershipPath();
      if (singleMembershipPath && (!to.path.includes("/a/user-profile") && !to.path.includes("/a/o") && !to.path.includes("/a/terms") && !to.path.includes("/a/profile") && !to.path.includes("/new/payments") && !to.path.includes("/a/callback"))) {
        next({ path: singleMembershipPath });
        return;
      }

      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
