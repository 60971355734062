/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListTaskActivitiesParams
} from '../../model/listTaskActivitiesParams'
import type {
  PageTaskActivity
} from '../../model/pageTaskActivity'
import type {
  TaskActivity
} from '../../model/taskActivity'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getTaskActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<TaskActivity>(
      {url: `/api/taskActivities/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskActivityQueryKey = (id: MaybeRef<string>,) => {
    return ['api','taskActivities',id] as const;
    }

    
export const getGetTaskActivityQueryOptions = <TData = Awaited<ReturnType<typeof getTaskActivity>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTaskActivityQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskActivity>>> = ({ signal }) => getTaskActivity(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskActivity>>, TError, TData> 
}

export type GetTaskActivityQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskActivity>>>
export type GetTaskActivityQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetTaskActivity = <TData = Awaited<ReturnType<typeof getTaskActivity>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTaskActivityQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateTaskActivity = (
    id: MaybeRef<string>,
    taskActivity: MaybeRef<TaskActivity>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
taskActivity = unref(taskActivity);
      
      return customAxios<TaskActivity>(
      {url: `/api/taskActivities/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: taskActivity
    },
      options);
    }
  


export const getUpdateTaskActivityMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskActivity>>, TError,{id: string;data: TaskActivity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTaskActivity>>, TError,{id: string;data: TaskActivity}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTaskActivity>>, {id: string;data: TaskActivity}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTaskActivity(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateTaskActivityMutationResult = NonNullable<Awaited<ReturnType<typeof updateTaskActivity>>>
    export type UpdateTaskActivityMutationBody = TaskActivity
    export type UpdateTaskActivityMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateTaskActivity = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskActivity>>, TError,{id: string;data: TaskActivity}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateTaskActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteTaskActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/taskActivities/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskActivityMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskActivity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskActivity>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskActivity>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteTaskActivity(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskActivityMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskActivity>>>
    
    export type DeleteTaskActivityMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteTaskActivity = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskActivity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteTaskActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listTaskActivities = (
    params?: MaybeRef<ListTaskActivitiesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageTaskActivity>(
      {url: `/api/taskActivities`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListTaskActivitiesQueryKey = (params?: MaybeRef<ListTaskActivitiesParams>,) => {
    return ['api','taskActivities', ...(params ? [params]: [])] as const;
    }

    
export const getListTaskActivitiesQueryOptions = <TData = Awaited<ReturnType<typeof listTaskActivities>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListTaskActivitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaskActivities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListTaskActivitiesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTaskActivities>>> = ({ signal }) => listTaskActivities(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTaskActivities>>, TError, TData> 
}

export type ListTaskActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof listTaskActivities>>>
export type ListTaskActivitiesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListTaskActivities = <TData = Awaited<ReturnType<typeof listTaskActivities>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListTaskActivitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaskActivities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTaskActivitiesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createTaskActivity = (
    taskActivity: MaybeRef<TaskActivity>,
 options?: SecondParameter<typeof customAxios>,) => {
      taskActivity = unref(taskActivity);
      
      return customAxios<TaskActivity>(
      {url: `/api/taskActivities`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskActivity
    },
      options);
    }
  


export const getCreateTaskActivityMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaskActivity>>, TError,{data: TaskActivity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createTaskActivity>>, TError,{data: TaskActivity}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTaskActivity>>, {data: TaskActivity}> = (props) => {
          const {data} = props ?? {};

          return  createTaskActivity(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateTaskActivityMutationResult = NonNullable<Awaited<ReturnType<typeof createTaskActivity>>>
    export type CreateTaskActivityMutationBody = TaskActivity
    export type CreateTaskActivityMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateTaskActivity = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaskActivity>>, TError,{data: TaskActivity}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateTaskActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForTaskActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/taskActivities/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForTaskActivityQueryKey = (id: MaybeRef<string>,) => {
    return ['api','taskActivities',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForTaskActivityQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForTaskActivityQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>> = ({ signal }) => getChangeSequenceForTaskActivity(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>, TError, TData> 
}

export type GetChangeSequenceForTaskActivityQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>>
export type GetChangeSequenceForTaskActivityQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForTaskActivity = <TData = Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForTaskActivityQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



