<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useRoute } from 'vue-router';
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import { type Task, type User, type TaskStatus, type TaskActivity } from "~/model";
import { getTask, updateTask } from "~/api/activity/activity";
import { useListProjects } from "~/api/projects/projects";
import { useListMemberships } from "~/api/memberships/memberships";
import { updateHandler } from "~/utils/error-handler";
import appStore from "~/store";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { isEqual } from 'lodash-es';
import { useListTaskActivities, useCreateTaskActivity } from "~/api/task-activity/task-activity";
import { formatDistanceToNow } from 'date-fns';
import TaskAdmin from '~/views/flow/organizations/project/task/task-admin.vue';
import TaskManage from '~/components/tasks/task-manage.vue';

// Store and Route
const route = useRoute();
const { project } = storeToRefs(appStore.projectStore);
const { currentOrganization } = storeToRefs(appStore.organizationStore);
const { user, currentUser } = storeToRefs(appStore.userStore);

// Task State
const currentTask = ref<Task | null>(null);
const originalTask = ref<Task | null>(null);

// Constants
const statusOptions = [
  { id: 'TODO', text: 'To Do' },
  { id: 'IN_PROGRESS', text: 'In Progress' },
  { id: 'DONE', text: 'Done' }
] as const;

const today = new Date();

// API Queries
const { data: projects } = useListProjects({
  page: 1,
  pageSize: 100,
  filter: `organization.id:'${currentOrganization.value?.id}'`,
  query: "",
  sort: "",
});

const { data: memberships } = useListMemberships({
  page: 1,
  pageSize: 100,
  filter: `organization.id:'${currentOrganization.value?.id}'`,
  sort: "",
});

const taskActivitiesFilter = computed(() => ({
  page: 1,
  pageSize: 50,
  filter: `task.id:'${route.params.taskId}'`,
  sort: "createdOn:asc"
}));

const {
  data: taskActivities,
  refetch: refetchActivities
} = useListTaskActivities(taskActivitiesFilter);
const createTaskActivityMutation = useCreateTaskActivity();

// Computed Properties
const assigneeOptions = computed(() =>
  memberships.value?.content.map(m => ({
    id: m.user.id,
    name: formatUserName(m.user),
    email: m.user.email,
    user: m.user,
    text: formatUserName(m.user),
    avatarUrl: m.user.avatarUrl
  })) ?? []
);

const selectedProjectId = computed({
  get: () => currentTask.value?.project?.id ?? "",
  set: (value: string) => {
    if (currentTask.value) {
      currentTask.value.project = projects.value?.content.find(p => p.id === value) ?? null;
    }
  }
});

const selectedAssigneeId = computed({
  get: () => currentTask.value?.assignee?.id ?? "",
  set: (value: string) => {
    if (currentTask.value) {
      currentTask.value.assignee = assigneeOptions.value.find(option => option.id === value)?.user ?? null;
    }
  }
});

const selectedStatus = computed({
  get: () => currentTask.value?.status ?? 'TODO',
  set: (value: string) => {
    if (currentTask.value) {
      currentTask.value.status = value as TaskStatus;
    }
  }
});

const taskDueDate = computed({
  get: () => currentTask.value?.dueDate ? new Date(currentTask.value.dueDate) : null,
  set: (value: Date | null) => {
    if (currentTask.value) {
      currentTask.value.dueDate = value?.toISOString() || null;
    }
  }
});

const hasChanges = computed(() => {
  if (!currentTask.value || !originalTask.value) return false;

  const currentValues = {
    title: currentTask.value.title,
    description: currentTask.value.description,
    status: currentTask.value.status,
    dueDate: currentTask.value.dueDate,
    project: currentTask.value.project?.id,
    assignee: currentTask.value.assignee?.id,
  };

  const originalValues = {
    title: originalTask.value.title,
    description: originalTask.value.description,
    status: originalTask.value.status,
    dueDate: originalTask.value.dueDate,
    project: originalTask.value.project?.id,
    assignee: originalTask.value.assignee?.id,
  };

  return !isEqual(currentValues, originalValues);
});

const formatUserName = (user: User): string =>
  `${user.firstName} ${user.lastName}`;

const formatTimestamp = (date: string): string => {
  try {
    const activityDate = new Date(date);
    const now = new Date();

    // Show "just now" for activities less than a minute old
    const timeDiff = now.getTime() - activityDate.getTime();
    if (timeDiff < 60000) {
      return 'just now';
    }

    return formatDistanceToNow(activityDate, {
      addSuffix: true,
      includeSeconds: true
    });
  } catch (e) {
    console.error('Error formatting date:', e);
    return 'recently';
  }
};

const formatActivityMessage = (activity: TaskActivity): string => {
  const userName = activity.user
    ? `<b>${activity.user.firstName} ${activity.user.lastName}</b>`
    : '<b>Someone</b>';

  if (!activity.detail?.field) {
    return `${userName} ${activity.content}`;
  }

  if (activity.detail?.field) {
    switch (activity.detail.field) {
      case 'status':
        const statusMap = {
          'TODO': 'To Do',
          'IN_PROGRESS': 'In Progress',
          'DONE': 'Done'
        };
        const oldStatus = activity.detail.oldValue || 'TODO';
        return `${userName} changed status from <b>${statusMap[oldStatus]}</b> to <b>${statusMap[activity.detail.newValue]}</b>`;

      case 'title':
        return `${userName} changed task name from "<b>${activity.detail.oldValue}</b>" to "<b>${activity.detail.newValue}</b>"`;

      case 'description':
        return `${userName} updated the task description`;

      case 'assignee':
        const newAssignee = assigneeOptions.value.find(a => a.id === activity.detail.newValue);
        const hasOldAssignee = activity.detail.oldValue && activity.detail.oldValue !== 'null';
        return `${userName} ${hasOldAssignee ? 're-assigned' : 'assigned'} to <b>${newAssignee ? formatUserName(newAssignee.user) : 'someone'}</b>`;

      case 'project':
        const oldProject = projects.value?.content.find(p => p.id === activity.detail.oldValue);
        const newProject = projects.value?.content.find(p => p.id === activity.detail.newValue);
        return `${userName} changed project from <b>${oldProject?.name || 'none'}</b> to <b>${newProject?.name}</b>`;

      case 'dueDate':
        const oldDate = activity.detail.oldValue && activity.detail.oldValue !== 'null'
          ? new Date(activity.detail.oldValue).toLocaleDateString()
          : null;
        const newDate = activity.detail.newValue
          ? new Date(activity.detail.newValue).toLocaleDateString()
          : 'none';

        return oldDate
          ? `${userName} changed due date from <b>${oldDate}</b> to <b>${newDate}</b>`
          : `${userName} set due date to <b>${newDate}</b>`;

      default:
        return `${userName} updated ${activity.detail.field}`;
    }
  }

  return `${userName} updated the task`;
};

// Helper function to detect changes
const getChanges = () => {
  if (!currentTask.value || !originalTask.value) return [];

  const changes = [];
  const current = currentTask.value;
  const original = originalTask.value;

  const fieldsToCheck = {
    title: { field: 'title', current: current.title, original: original.title },
    status: { field: 'status', current: current.status, original: original.status },
    assignee: { field: 'assignee', current: current.assignee?.id, original: original.assignee?.id },
    dueDate: { field: 'dueDate', current: current.dueDate, original: original.dueDate },
    project: { field: 'project', current: current.project?.id, original: original.project?.id },
    description: { field: 'description', current: current.description, original: original.description }
  };

  Object.values(fieldsToCheck).forEach(({ field, current, original }) => {
    if (current !== original) {
      changes.push({ field, oldValue: original, newValue: current });
    }
  });

  return changes;
};

const formatChangeMessage = (change: { field: string; oldValue: any; newValue: any }): string => {
  const messageMap = {
    status: () => {
      const statusMap = {
        'TODO': 'To Do',
        'IN_PROGRESS': 'In Progress',
        'DONE': 'Done'
      };
      const oldStatus = change.oldValue || 'TODO';
      return `changed status from <b>${statusMap[oldStatus]}</b> to <b>${statusMap[change.newValue]}</b>`;
    },

    assignee: () => {
      const newAssignee = assigneeOptions.value.find(a => a.id === change.newValue);
      const hasOldAssignee = change.oldValue && change.oldValue !== 'null';

      // Check if the assignee is the current user
      const isSelfAssigned = newAssignee?.id === user.value?.id;

      if (isSelfAssigned) {
        return 'self assigned the task';
      }

      return `${hasOldAssignee ? 're-assigned' : 'assigned'} to <b>${newAssignee ? formatUserName(newAssignee.user) : 'someone'}</b>`;
    },

    dueDate: () => {
      const oldDate = change.oldValue && change.oldValue !== 'null'
        ? new Date(change.oldValue).toLocaleDateString()
        : null;
      const newDate = change.newValue
        ? new Date(change.newValue).toLocaleDateString()
        : 'none';

      return oldDate
        ? `changed due date from <b>${oldDate}</b> to <b>${newDate}</b>`
        : `set due date to <b>${newDate}</b>`;
    },

    project: () => {
      const oldProject = projects.value?.content.find(p => p.id === change.oldValue);
      const newProject = projects.value?.content.find(p => p.id === change.newValue);
      return `changed project from <b>${oldProject?.name || 'none'}</b> to <b>${newProject?.name}</b>`;
    },

    description: () => 'updated the task description',

    title: () => `changed task name from "<b>${change.oldValue}</b>" to "<b>${change.newValue}</b>"`,

    default: () => `Updated ${change.field}`
  };

  return (messageMap[change.field] || messageMap.default)();
};

const refreshTaskData = async (): Promise<void> => {
  try {
    const task = await getTask(route.params.taskId as string);
    currentTask.value = { ...task };
    originalTask.value = { ...task };
    await nextTick();
    initializeTextareaHeight();
  } catch (error) {
    updateHandler(Promise.reject(error), "Failed to refresh task data");
  }
};

const saveChanges = async (): Promise<void> => {
  if (!currentTask.value?.id || !hasChanges.value) return;

  try {
    const changes = getChanges();

    // Save the task
    const updatedTask = await updateTask(currentTask.value.id.toString(), {
      ...currentTask.value,
      changeSequence: originalTask.value?.changeSequence
    });

    // Create activity records
    await Promise.all(changes.map(change =>
      createTaskActivityMutation.mutateAsync({
        data: {
          task: { id: currentTask.value!.id },
          user: user.value,
          content: formatChangeMessage(change),
          detail: {
            field: change.field,
            oldValue: change.oldValue?.toString() || '',
            newValue: change.newValue?.toString() || ''
          }
        }
      })
    ));

    // Refresh the activities list
    await refetchActivities();

    currentTask.value = { ...updatedTask };
    originalTask.value = { ...updatedTask };
    updateHandler(Promise.resolve(), "Task updated successfully");
  } catch (error) {
    console.error('Error saving changes:', error);
    if (error.response?.status === 409) {
      await refreshTaskData();
      updateHandler(
        Promise.reject(error),
        "Task was modified by another user. The latest version has been loaded."
      );
    } else {
      updateHandler(
        Promise.reject(error),
        "Failed to save task. Please try again."
      );
    }
  }
};

const cancelChanges = () => {
  if (originalTask.value) {
    currentTask.value = JSON.parse(JSON.stringify(originalTask.value));
    nextTick(() => {
      initializeTextareaHeight();
    });
  }
};

// Textarea handling
const initializeTextareaHeight = () => {
  const textarea = document.querySelector('textarea');
  if (textarea) {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
};

const handleTextareaResize = (event: Event) => {
  const textarea = event.target as HTMLTextAreaElement;
  textarea.style.height = 'auto';
  textarea.style.height = `${textarea.scrollHeight}px`;
};

watch(() => currentTask.value?.description, () => {
  nextTick(() => {
    initializeTextareaHeight();
  });
});

const currentTab = ref(undefined);

// Initialize the first tab
watch(computed(() => currentTab.value), () => {
  if (!currentTab.value) {
    currentTab.value = {
      ref: "taskManage",
      name: "Manage",
      singleWidget: {
        type: "taskManage",
      },
    };
  }
}, { immediate: true });

// Lifecycle
onMounted(() => {
  refreshTaskData();
});
</script>

<template>
  <div class="flex h-[90vh]">
    <div class="flex-1">
      <div class="grid grid-cols-1 lg:grid-cols-12 gap-6">
        <!-- Left Content -->
        <div class="col-span-1 lg:col-span-8 xl:col-span-9 overflow-y-auto h-[90vh] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
          <!-- Tabs Navigation -->
          <TaskAdmin v-model:currentTab="currentTab" />

          <!-- Tab Content -->
          <div class="mt-6 px-4 lg:px-14">
            <TaskManage
              v-if="currentTab?.ref === 'taskManage' && currentTask"
              v-model:currentTask="currentTask"
              :task-activities="taskActivities"
              :format-activity-message="formatActivityMessage"
              :format-timestamp="formatTimestamp"
              :handle-textarea-resize="handleTextareaResize"
            />
            <div v-else-if="currentTab?.ref === 'taskWorkspace'">
              Workspace Content
            </div>
          </div>
        </div>

        <!-- Right Sidebar -->
        <div class="col-span-1 lg:col-span-4 xl:col-span-3 h-auto lg:h-[90vh] px-4 lg:px-6 py-6 bg-white">
          <div v-if="currentTask" class="space-y-6">
            <!-- Status -->
            <div>
              <h3 class="text-gray-500 mb-2">Status</h3>
              <KodexaDropDown
                id="status"
                name="status"
                v-model="selectedStatus"
                :items="statusOptions"
                value-field="id"
                text-field="text"
                placeholder="Status"
                class="w-full"
              />
            </div>

            <!-- Assignee -->
            <div>
              <h3 class="text-gray-500 mb-2">Assignee</h3>
              <KodexaDropDown
                id="assignee"
                name="assignee"
                v-model="selectedAssigneeId"
                :items="assigneeOptions"
                value-field="id"
                text-field="text"
                placeholder="Assignee"
                class="mt-1"
                v-tooltip="selectedAssigneeId ? `${assigneeOptions.find(a => a.id === selectedAssigneeId)?.name} (${assigneeOptions.find(a => a.id === selectedAssigneeId)?.email})` : ''"
              >
                <template #item-render="{ item }">
                  <div class="flex items-center gap-2">
                    <KodexaUserAvatar
                      :user="item.user"
                      size="6"
                      class="flex-shrink-0"
                    />
                    <div class="flex flex-col overflow-hidden">
                      <span class="font-medium" :title="item.name">{{ item.name }}</span>
                      <span class="text-xs text-gray-500" :title="item.email">{{ item.email }}</span>
                    </div>
                  </div>
                </template>
                <template #value-render="{ item }">
                  <div class="flex items-center gap-2">
                    <KodexaUserAvatar
                      v-if="item?.user"
                      :user="item.user"
                      size="6"
                      class="flex-shrink-0"
                    />
                    <span class="font-medium truncate" :title="item?.name">{{ item?.name }}</span>
                  </div>
                </template>
              </KodexaDropDown>
            </div>

            <!-- Project -->
            <div>
              <h3 class="text-gray-500 mb-2">Project</h3>
              <KodexaDropDown
                id="project"
                name="project"
                v-model="selectedProjectId"
                :items="projects?.content ?? []"
                value-field="id"
                text-field="name"
                placeholder="Project"
                class="w-full"
              />
            </div>

            <!-- Due Date -->
            <div>
              <h3 class="text-gray-500 mb-2">Due Date</h3>
              <DatePicker
                v-model="taskDueDate"
                :min="today"
                class="w-full date-picker-custom"
                :clearable="true"
                placeholder="Set Due Date"
              />
            </div>

            <!-- Save and Cancel Buttons -->
            <div v-if="hasChanges" class="flex justify-around mt-5">
              <KodexaButton
                type="primary"
                icon="content-save"
                class="w-28"
                @click="saveChanges"
              >
                Save
              </KodexaButton>
              <KodexaButton
                type="secondary"
                icon="cancel"
                class="w-28"
                @click="cancelChanges"
              >
                Cancel
              </KodexaButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.date-picker-custom) {
  height: 32px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  border-color: #D1D5DB !important;
  background-color: white !important;
  background-image: none !important;
  @apply rounded-md shadow-sm;
}

:deep(.date-picker-custom .k-input:hover),
:deep(.date-picker-custom .k-input.k-focus) {
  border-color: #9CA3AF !important;
}

:deep(.date-picker-custom .k-input-inner) {
  @apply text-sm text-gray-900;
}

:deep(.date-picker-custom .k-input-inner::placeholder) {
  @apply text-sm text-gray-500;
}

:deep(.k-button) {
  --tw-ring-offset-shadow: 0 0 #0000 !important;
  --tw-ring-shadow: 0 0 #0000 !important;
}
</style>

