<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { type Task, type User, type TaskStatus } from "~/model";
import { createTask, updateTask } from "~/api/activity/activity";
import { useListProjects } from "~/api/projects/projects";
import { useListMemberships } from "~/api/memberships/memberships";
import { updateHandler } from "~/utils/error-handler";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import appStore from "~/store";
import { useCreateTaskActivity } from "~/api/task-activity/task-activity";

interface Props {
  modelValue: boolean;
  taskToEdit: Task | null;
}

const props = withDefaults(defineProps<Props>(), {
  taskToEdit: null,
});

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  'task-created': []
  'task-updated': []
  'update:taskToEdit': [value: Task | null]
}>();

const { project } = storeToRefs(appStore.projectStore);
const { currentOrganization } = storeToRefs(appStore.organizationStore);
const { user, currentUser } = storeToRefs(appStore.userStore);

const isExpanded = ref(false);
const newTask = ref<Task>({
  title: "",
  project: null,
  description: "",
  status: 'TODO' as TaskStatus,
  dueDate: null,
  metadata: {
    assignee: "",
  },
});

const { data: projects } = useListProjects({
  page: 1,
  pageSize: 100,
  filter: `organization.id:'${currentOrganization.value?.id}'`,
  query: "",
  sort: "",
});

const { data: memberships } = useListMemberships({
  page: 1,
  pageSize: 100,
  filter: `organization.id:'${currentOrganization.value?.id}'`,
  sort: "",
});

const createTaskActivityMutation = useCreateTaskActivity();

const selectedProjectId = computed({
  get: () => newTask.value.project?.id ?? "",
  set: (value: string) => {
    newTask.value.project = projects.value?.content.find(p => p.id === value) ?? null;
  }
});

const assigneeOptions = computed(() =>
  memberships.value?.content.map(m => ({
    id: m.user.id,
    name: formatUserName(m.user),
    email: m.user.email,
    user: m.user,
    text: formatUserName(m.user),
    avatarUrl: m.user.avatarUrl
  })) ?? []
);

const selectedAssigneeId = computed({
  get: () => newTask.value.assignee?.id ?? "",
  set: (value: string) => {
    newTask.value.assignee = assigneeOptions.value.find(option => option.id === value)?.user ?? null;
  }
});

const statusOptions = [
  { id: 'TODO', text: 'To Do' },
  { id: 'IN_PROGRESS', text: 'In Progress' },
  { id: 'DONE', text: 'Done' }
];

const selectedStatus = computed({
  get: () => newTask.value.status ?? 'TODO',
  set: (value: string) => {
    if (newTask.value) {
      newTask.value.status = value as TaskStatus;
    }
  }
});

const today = new Date();

const taskDueDate = computed({
  get: () => newTask.value.dueDate ? new Date(newTask.value.dueDate) : null,
  set: (value: Date | null) => {
    newTask.value.dueDate = value?.toISOString() || null;
  }
});

watch(() => props.modelValue, (value) => {
  if (value && props.taskToEdit) {
    newTask.value = {
      ...JSON.parse(JSON.stringify(props.taskToEdit)),
      changeSequence: props.taskToEdit.changeSequence,
      assignee: props.taskToEdit.assignee
    };
  } else {
    newTask.value = {
      title: "",
      description: "",
      project: project.value,
      assignee: null,
      metadata: {}
    };
  }
}, { immediate: true });

watch(() => props.modelValue, (value) => {
  if (!value) {
    isExpanded.value = false;
  }
});

const formatUserName = (user: User): string => `${user.firstName} ${user.lastName}`;

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

const close = () => {
  isExpanded.value = false;
  emit("update:modelValue", false);
  emit("update:taskToEdit", null);
};

async function save() {
  if (!newTask.value.title?.trim() || !newTask.value.project) return;

  try {
    if (props.taskToEdit?.id) {
      const taskId = props.taskToEdit.id.toString();
      await updateTask(taskId, {
        ...newTask.value,
        changeSequence: props.taskToEdit.changeSequence
      });
      emit("task-updated");
    } else {
      // Create the task first
      const createdTask = await createTask(newTask.value);

      // Create the initial activity using the mutation
      await createTaskActivityMutation.mutateAsync({
        data: {
          task: { id: createdTask.id },
          user: user.value,
          content: "created the task",
          detail: {
            field: 'creation',
            oldValue: '',
            newValue: ''
          }
        }
      });

      updateHandler(Promise.resolve(), "Task created successfully");
      emit("task-created");
    }
    close();
  } catch (error: any) {
    console.error("Failed to save task:", error);
    updateHandler(
      Promise.reject(error),
      error.response?.status === 409
        ? "Task was modified by another user. Please refresh and try again."
        : "Failed to save task"
    );
  }
}
</script>

<template>
  <TransitionRoot appear as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0 bg-gray-500/75 transition-opacity duration-300" />
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-start justify-center p-4 text-center sm:p-0">
          <DialogPanel
            :class="`
              relative w-full transform overflow-visible rounded-lg bg-white
              text-left shadow-xl transition-all duration-300 ease-in-out z-50
              ${isExpanded ? 'sm:max-w-[65vw] sm:h-[90vh] sm:mt-8' : 'sm:max-w-4xl sm:mt-48'}
            `"
          >
            <div class="flex flex-col h-full">
              <!-- Header -->
              <div class="flex items-center justify-between px-4 py-3">
                <span class="px-2 py-1 rounded-md border-2 border-gray-100 text-sm font-bold">
                  {{ taskToEdit ? 'Update Task' : 'New Task' }}
                </span>
                <div class="flex items-center space-x-4">
                  <button
                    @click="toggleExpand"
                    class="text-gray-400 hover:text-gray-600 transition-colors duration-200"
                  >
                    <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                    </svg>
                  </button>
                  <button
                    @click="close"
                    class="text-gray-400 hover:text-gray-600 transition-colors duration-200"
                  >
                    <span class="sr-only">Close</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <!-- Content -->
              <div class="flex-grow px-4 py-4">
                <input
                  v-model="newTask.title"
                  class="w-full bg-transparent text-2xl text-gray-900 placeholder-gray-400 border-none focus:ring-0 focus:outline-none"
                  placeholder="Task Name"
                />
                <textarea
                  v-model="newTask.description"
                  class="w-full h-5/6 mt-4 bg-transparent text-gray-700 placeholder-gray-400 border-none focus:ring-0 focus:outline-none resize-none"
                  placeholder="Add description..."
                />
              </div>

              <!-- Dropdowns -->
              <div class="px-4 py-4">
                <div class="flex flex-wrap gap-2">
                  <!-- Status Dropdown -->
                  <div :class="[selectedStatus ? 'w-fit max-w-64' : 'w-52']">
                    <KodexaDropDown
                      id="status"
                      name="status"
                      v-model="selectedStatus"
                      :items="statusOptions"
                      value-field="id"
                      text-field="text"
                      placeholder="Status"
                      class="mt-1"
                    />
                  </div>

                  <!-- Assignee Dropdown -->
                  <div :class="[selectedAssigneeId ? 'w-fit max-w-64' : 'w-52']">
                    <KodexaDropDown
                      id="assignee"
                      name="assignee"
                      v-model="selectedAssigneeId"
                      :items="assigneeOptions"
                      value-field="id"
                      text-field="text"
                      placeholder="Assignee"
                      class="mt-1"
                      v-tooltip="selectedAssigneeId ? `${assigneeOptions.find(a => a.id === selectedAssigneeId)?.name} (${assigneeOptions.find(a => a.id === selectedAssigneeId)?.email})` : ''"
                    >
                      <template #item-render="{ item }">
                        <div class="flex items-center gap-2">
                          <KodexaUserAvatar
                            :user="item.user"
                            size="6"
                            class="flex-shrink-0"
                          />
                          <div class="flex flex-col overflow-hidden">
                            <span class="font-medium" :title="item.name">{{ item.name }}</span>
                            <span class="text-xs text-gray-500" :title="item.email">{{ item.email }}</span>
                          </div>
                        </div>
                      </template>
                      <template #value-render="{ item }">
                        <div class="flex items-center gap-2">
                          <KodexaUserAvatar
                            v-if="item?.user"
                            :user="item.user"
                            size="6"
                            class="flex-shrink-0"
                          />
                          <span class="font-medium truncate" :title="item?.name">{{ item?.name }}</span>
                        </div>
                      </template>
                    </KodexaDropDown>
                  </div>

                  <!-- Project Dropdown -->
                  <div :class="[selectedProjectId ? 'w-fit max-w-64' : 'w-52']">
                    <KodexaDropDown
                      id="project"
                      name="project"
                      v-model="selectedProjectId"
                      :items="projects?.content ?? []"
                      value-field="id"
                      text-field="name"
                      placeholder="Project"
                      class="mt-1"
                      v-tooltip="projects?.content.find(p => p.id === selectedProjectId)?.name ?? ''"
                    />
                  </div>

                  <!-- Due Date Picker -->
                  <div class="w-52">
                    <DatePicker
                      v-model="taskDueDate"
                      :min="today"
                      class="w-full date-picker-custom mt-1"
                      :clearable="true"
                      placeholder="Set Due Date"
                    />
                  </div>
                </div>
              </div>

              <!-- Footer -->
              <div class="flex items-center justify-end px-4 py-3 border-t border-gray-200">
                <KodexaButton
                  type="primary"
                  class="ml-2"
                  icon="content-save"
                  @click="save"
                >
                  {{ taskToEdit ? 'Save Changes' : 'Create Task' }}
                </KodexaButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
:deep(.date-picker-custom) {
  height: 32px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  border-color: #D1D5DB !important;
  background-color: white !important;
  background-image: none !important;
  @apply rounded-md shadow-sm;
}

:deep(.date-picker-custom .k-input:hover),
:deep(.date-picker-custom .k-input.k-focus) {
  border-color: #9CA3AF !important;
}

:deep(.date-picker-custom .k-input-inner) {
  @apply text-sm text-gray-900;
}

:deep(.date-picker-custom .k-input-inner::placeholder) {
  @apply text-sm text-gray-500;
}

:deep(.k-button) {
  --tw-ring-offset-shadow: 0 0 #0000 !important;
  --tw-ring-shadow: 0 0 #0000 !important;
}
</style>
