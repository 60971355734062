<script lang="ts" setup>
import { type PropType, computed, toRefs } from "vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import type { AttributeEditorOptions } from "~/components/dataObject/attribute-editor-options";
import { formatAggregationByTaxonType } from "~/components/util/attribute-utils";

const props = defineProps({
  field: {
    type: String,
    required: true,
  },
  dataItem: {
    type: Object,
    required: true,
  },
  columnIndex: {
    type: Number,
    required: true,
  },
  columnsCount: {
    type: Number,
    required: true,
  },
  rowType: {
    type: String,
    required: true,
  },
  level: {
    type: Number,
    required: true,
  },
  className: {
    type: String,
    required: true,
  },
  style: {
    type: Object,
    required: true,
  },
  colSpan: {
    type: Number,
    required: false,
    default: 1,
  },
  expanded: {
    type: Boolean,
    required: true,
  },
  editorOptions: {
    type: Object as PropType<AttributeEditorOptions>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["click"]);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

function nestedValue(fieldName: string, dataItem: any) {
  const path = fieldName.split(".");
  let data = dataItem;
  path.forEach((p) => {
    data = data ? data[p] : undefined;
  });

  return data;
}

const { field, dataItem, columnIndex, columnsCount, rowType, level, expanded } = toRefs(props);

const renderArrow = computed(() => {
  const returnValue
    = columnIndex.value === undefined
    || level.value === undefined
    || columnIndex.value < level.value
    || columnsCount.value === undefined
    || rowType.value !== "groupHeader"
    || (dataItem.value && field.value && dataItem.value[field.value] === undefined);
  return returnValue && dataItem.value[field.value];
});

const renderCell = computed(() => {
  return field.value !== undefined && rowType.value !== "groupHeader";
});

const renderAggregates = computed(() => {
  if (field.value in dataItem.value.aggregates) {
    const aggregate = dataItem.value.aggregates[field.value];
    const taxonMeta = tagMetadataMap.value.get(field.value);
    return formatAggregationByTaxonType(aggregate, taxonMeta.taxon);
  }
  return "";
});

function onClick(e: MouseEvent) {
  emit("click", e, props.dataItem, props.expanded);
}

function getHeader() {
  return dataItem.value.value;
}

const { dataObjects } = storeToRefs(appStore.workspaceStore);

// We need to make sure if we have something in the workspace
// we are using that
const finalDataObject = computed(() => {
  return dataObjects.value.has(props.dataItem.dataObjectInstance.uuid)
    ? dataObjects.value.get(props.dataItem.dataObjectInstance.uuid)
    : props.dataItem.dataObjectInstance;
});
</script>

<template>
  <td v-if="rowType === 'groupFooter'">
    <div class="pr-8 flex justify-end">
      {{ renderAggregates }}
    </div>
  </td>
  <td v-else-if="renderCell" :class="className" :colspan="props.colSpan" :style="props.style">
    <MaterialDesignIcon
      v-if="field === 'expanded'"
      :name="expanded ? 'chevron-down' : 'chevron-right'"
      size="16"
      class="-ml-2"
      @click="onClick"
    />
    <div v-if="tagMetadataMap.has(props.field) && props.dataItem?.items">
      <KodexaDataAttributeEditor
        v-for="item in props.dataItem.items" :key="`${item.dataObjectInstance.uuid}-${props.field}`"
        :view-id="viewId"
        :tag-metadata="tagMetadataMap.get(props.field)"
        :data-object="item.dataObjectInstance"
        :editor-options="editorOptions"
      />
    </div>
    <div v-else-if="tagMetadataMap.has(props.field)">
      <KodexaDataAttributeEditor
        :view-id="viewId"
        :tag-metadata="tagMetadataMap.get(props.field)"
        :data-object="finalDataObject"
        :editor-options="editorOptions"
      />
    </div>
  </td>
  <td
    v-else-if="rowType === 'groupHeader' && columnIndex <= level"
    key="g-colspan"
    :colSpan="columnsCount - columnIndex"
  >
    <p class="k-reset">
      <a
        tabIndex="-1"
        href="#"
        :class="expanded ? 'k-i-collapse k-icon' : 'k-i-expand k-icon'"
        @click="onClick"
      />
      {{ getHeader() }}
    </p>
  </td>
  <td v-else-if="renderArrow" :key="`g${columnIndex}`" class="k-group-cell" />
  <td v-else-if="rowType !== 'groupHeader'" :class="className">
    {{ nestedValue(field, dataItem) }}
  </td>
</template>
